import LayoutDefault from "./layouts/LayoutDefault";

const App = () => {
  return (
    <div className="app">
      <LayoutDefault />
    </div>
  );
}

export default App;
